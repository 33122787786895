import debounce from "lodash/debounce";

export class Sticky {
	private documentHelper = document;
	private windowHelper = window;
	private classToTrigger = "js_sticky_trigger";
	private classToAdd = "data-class";
	private classToTarget = "data-target";

	constructor() {
		this.Init = debounce(this.Init.bind(this), 1000 / 60, { leading: true });
	}

	// tested
	public Init() {
		this.handleStickyElements();
		this.documentHelper.addEventListener("scroll", this.Init);
		this.windowHelper.addEventListener("resize", this.Init);
	}

	// tested
	private handleStickyElements() {
		const stickyElements = this.documentHelper.querySelectorAll<HTMLElement>(`.${this.classToTrigger}`);
		stickyElements.forEach((element) => {
			this.handleStickyElement(element);
		});
	}

	// tested
	private handleStickyElement(element: HTMLElement) {
		const targetElementSelector = element.getAttribute(this.classToTarget);
		const targetElement = targetElementSelector ? this.documentHelper.querySelector<HTMLElement>(targetElementSelector) : null;

		const classToAdd = element.getAttribute(this.classToAdd);

		if (targetElement && classToAdd) {
			if (classToAdd === "bottom") {
				const offsetValue = targetElement.offsetHeight + 100;
				this.toggleStickyClass(targetElement, this.isElementSticky(element, offsetValue), classToAdd);
			} else {
				this.toggleStickyClass(targetElement, this.isElementSticky(element), classToAdd);
			}
		}
	}

	// tested
	private isElementSticky(triggerElement: HTMLElement, offset: number = 0) {
		const topOffset = this.getTopOffset(triggerElement);
		return this.windowHelper.scrollY >= topOffset - offset;
	}

	// tested
	private toggleStickyClass(element: HTMLElement, isSticky: boolean, classToAdd: string) {
		element.classList.toggle(classToAdd, isSticky);
	}

	// tested
	private getTopOffset(el: HTMLElement) {
		let offsetTop = 0;
		while (el) {
			offsetTop += el.offsetTop;
			el = el.offsetParent as HTMLElement;
		}
		return offsetTop;
	}
}
