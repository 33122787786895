export class InteractionHelper {
	// tested
	public Init() {
		this.tabHelper();
		this.clickHelper();
		this.enterHelper();
	}

	// tested
	private tabHelper() {
		document.addEventListener("keydown", (evt: KeyboardEvent) => {
			if (evt.key === "Tab") {
				document.documentElement.classList.add("key-pressed");
				document.documentElement.classList.remove("mouse-clicked");
			}
		});
	}

	// tested
	private clickHelper() {
		document.addEventListener(
			"click",
			() => {
				document.documentElement.classList.add("mouse-clicked");
				document.documentElement.classList.remove("key-pressed");
			},
			true
		);
	}

	// tested
	private enterHelper() {
		const elements = document.querySelectorAll(".enterInteraction");
		for (let i = 0; i < elements.length; i++) {
			this.addEnterListener(elements[i]);
		}
	}

	// tested
	private addEnterListener(element) {
		element.addEventListener("keyup", (evt: KeyboardEvent) => {
			if (evt.key === "Enter") {
				element.click();
			}
		});
	}
}
